@import url(https://fonts.googleapis.com/css?family=Nunito:400,600,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Nunito', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  /* Disables pull-to-refresh but allows overscroll glow effects. */
  overscroll-behavior-y: contain;
  font-family: 'Nunito';
}

#fabScan { display: none; }

/* Sidebar */
/* 
.link { text-decoration: none; }
.link .MuiListItem-button { padding-top: 4px; padding-bottom: 4px; }
.link .MuiListItemText-primary { color: #333 !important; font-size: 22px; text-decoration: none; }
.link.link-current .MuiListItemText-primary { font-weight: bold; }   
*/

@media only screen and (max-width: 906px) {
  #fabScan { display: grid; background-color: #bd598f !important; }
}
@media (min-width: 1280px) {
  .MuiContainer-maxWidthLg {
    max-width: unset !important;
  }
}

.MuiChip-clickable, .MuiChip-deletable { margin-left: 2px !important; margin-right: 2px !important; }

.OTPublisherContainer {
  position: absolute;
  z-index: 101;
  bottom: 10px;
  right: 10px;
  width: 30vw !important;
  height: 30vw !important;
  max-width: 240px;
  max-height: 240px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0px 15px #000;
}
.OTSubscriberContainer {
  position: absolute;
  z-index: 100;
  top: 0px;
  left: 0px; 
  width: 100vw !important;
  height: 100vh !important;
  border: none;
}
button.OT_edge-bar-item {
  opacity: 1 !important;
  top: 5px !important;
  right: 5px !important;
  border: none !important;
  outline: none !important;
}
.OT_edge-bar-item {
  opacity: 0 !important;
}
.OT_mini .OT_mute, .OT_publisher.OT_mini .OT_mute.OT_mode-auto.OT_mode-on-hold, .OT_subscriber.OT_mini .OT_mute.OT_mode-auto.OT_mode-on-hold {
  margin-top: -5px !important; left: unset !important;
}
